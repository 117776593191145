body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  font-family: "Montserrat", sans-serif;
  background-color: #000034;
  #root {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
  }
}
.login-form {
  width: 552px !important;
}

.modal-content {
  border-radius: 20px !important;
}
.modal {
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  background: rgba(23, 23, 23, 0.28);
}
.register-modal {
  .modal-dialog {
    max-width: 670px !important;
  }
}
.modal-lg,
.modal-xl {
  max-width: 715px !important;
}
.goals-modal {
  .modal-dialog {
    max-width: 650px;
  }
}

.google-map-style {
  min-height: 100px;
  div {
    div {
      border-radius: 20px !important;
    }
  }
}
@media screen and (max-width: 576px) {
  .login-form {
    width: 100% !important;
  }
}
