.goals-onboarding-placeholder {
  font-family: $openSan;

  &::-webkit-input-placeholder {
    font-size: 16px !important;
    color: #2c2c2c;
    font-weight: 300;
  }
  &:-moz-placeholder {
    font-size: 16px !important;
    color: #2c2c2c;
    font-weight: 300;
  }
  &::-moz-placeholder {
    font-size: 16px !important;
    color: #2c2c2c;
    font-weight: 300;
  }
}

// input[type="number"]::placeholder {
//   color: #989898;
//   font-size: 18px;
//   font-style: normal;
//   font-family: "Montserrat";
//   font-weight: 400;
// }
// input[type="number"]::-moz-placeholder {
//   color: #989898;
//   font-size: 18px;
//   font-style: normal;
//   font-family: "Montserrat";
//   font-weight: 400;
// }
// input::placeholder {
//   color: #989898;
//   font-size: 18px;
//   font-style: normal;
//   font-family: "Montserrat";
//   font-weight: 400;
// }
// input::-moz-placeholder {
//   color: #989898;
//   font-size: 18px;
//   font-style: normal;
//   font-family: "Montserrat";
//   font-weight: 400;
// }
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}

.form-control:focus {
  color: #212529;
  background-color: #ffffff !important;
  border-color: none;
  outline: 0;
  box-shadow: none;
}
