$enable-responsive-font-size: true;
$headings-font-family: "Montserrat", sans-serif;
$primary-font-family: "Montserrat", sans-serif;
$openSan: "Open Sans";
$font-family-base: $primary-font-family;
$gray-100: #f8f9fa;
$gray-900: #212529;

//Basic Color
$skyblue: #0066ff33;
$primary: #0066ff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #198754;
$teal: #20c997;
$cyan: #0dcaf0;

// Theme Colors
$themeBg: #8fd0ff; // Theme BG Color    // 1st Goal Color
$sidebarBg: #000034; // Sidebar BG Color
$visualBlue: #22dbff; // Sidebar Hover Color
$white: #ffffff;
$black: #000000;
$darkergrey: #979797;
$activeColor: #22a2ff; // Active Color
$orange: #ffaa33;
$lighOrange: #dfa34f;
$chronoOrange: #ff4225;
$pureOrange: #ec5237; // Chrono Stats BG Color
$ghost: #ccccd6; // Nav Link Color Sidebar
$chipBlueLight: #eff5fd; // Notification Panel BG Color
$grey: #9999ae;
$lightGrey: #ededed;

$link-color: $activeColor;
$link-hover-color: $activeColor;

$primary: $activeColor;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-900 !default;

$theme-colors: (
  "primary": $primary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "theme": $themeBg,
  "sidebar": $sidebarBg,
  "lightBlue": $chipBlueLight,
  "white": $white,
  "black": $black,
  "pureOrange": $pureOrange,
  "chronoOrange": $chronoOrange,
  "ghost": $ghost,
  "grey": $grey,
  "darkerGrey": $darkergrey,
  "lightGrey": $lightGrey,
);

// Break points
$smallScreen: 576px;
$mediumScreen: 768px;
$largeScreen: 992px;
$xLargeScreen: 1200px;
$xxLargeScreen: 1400px;

@import "~bootstrap/scss/bootstrap";
