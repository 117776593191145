.accordian-header-dark {
  .accordion-button,
  .accordion-button:not(.collapsed) {
    background-color: $black !important;
    color: $white;
  }
}

.custom-header-general {
  border-radius: 15px;
  .accordion-button {
    border-radius: 15px;
    background-color: $white;
    color: $black;
    font-size: 16px;
    font-weight: 400;
    padding: 16px 20px !important;
    &:after {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000034'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
    }

    &:hover {
      background-color: $sidebarBg;
      color: $white;

      &:after {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
      }
    }
  }
  .accordion-button:not(.collapsed) {
    box-shadow: none;
    background-color: $sidebarBg !important;
    color: $white !important;
    &:after {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
    }
  }
}

.custom-header-stats {
  border-radius: 15px;
  border: none;
  .accordion-button {
    border-radius: 15px;
    background-color: $white;
    color: $black;
    font-size: 16px;
    font-weight: 500;
    padding: 18px 20px !important;
    &:after {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000034'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
    }
    &:hover {
      background-color: $sidebarBg;
      color: $white;
      border: none !important;
      &:after {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
      }
    }
  }
  .accordion-button:not(.collapsed) {
    border-bottom: 1px solid #ccccd6 !important;
    &:after {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23F04858'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
    }
  }
}

.custom-header-goals {
  border-radius: 20px;
  .accordion-button {
    border-radius: 15px;
    color: $sidebarBg;
    padding: 16px 20px !important;
    &:after {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000034'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
    }
    &:hover {
      background-color: $sidebarBg;
      color: $white;
      &:after {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
      }
    }
  }
  .accordion-button:not(.collapsed) {
    box-shadow: none;
    background-color: $sidebarBg !important;
    color: $white !important;
    &:after {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
    }
  }
}
.custom-header-goals-ongoing {
  border-radius: 15px;
  border: 1px solid #c4c4c4;
  .accordion-button {
    font-size: 16px;
    font-weight: 400;
    padding: 18px 20px !important;
    &:after {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000034'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
    }
    &:hover {
      background-color: $sidebarBg;
      color: $white;
      &:after {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
      }
    }
  }
  .accordion-button:not(.collapsed) {
    box-shadow: none;
    background-color: $sidebarBg !important;
    color: $white !important;
    &:after {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
    }
  }
}

.goals-accordion {
  border: none;
  .accordion-header {
    box-shadow: none;
    .accordion-button {
      box-shadow: none;
      border-radius: 15px !important;
      padding: 23px 15px !important;
      font-size: 16px !important;
      color: #0e0e0e !important;
      font-weight: 500 !important;
      transition: all 0.5s;
      &:focus {
        box-shadow: none;
      }
      &::after {
        content: "";
        background-image: none;
      }
    }
    .accordion-button:not(.collapsed) {
      box-shadow: none;
      background: none !important;
      padding: 0px 20px !important;
      color: $sidebarBg !important;
    }
  }
}

// Accordion Customize
.accordion {
  .accordion-item {
    border-radius: 15px;
    box-shadow: none;
    .accordion-header {
      box-shadow: none;
      .accordion-button {
        border-radius: 15px;
        padding: 18px 15px;

        &:focus {
          box-shadow: none;
        }
      }
      .accordion-button:not(.collapsed) {
        background: $white;
        box-shadow: none;
        color: $sidebarBg;
        // border: 1px solid #c4c4c4;
      }
    }
  }
}
