.left-0 {
  left: 0px !important;
}
.right-0 {
  right: 0px !important;
}
.right-15 {
  right: 15px !important;
}
.top-15 {
  top: -15px !important;
}
.top-10 {
  top: -10px !important;
}
.top-20 {
  top: -20px !important;
}
.mt-300 {
  margin-top: 300px;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.fs-22 {
  font-size: 22px !important;
}
.fs-24 {
  font-size: 24px !important;
}
.fs-26 {
  font-size: 26px !important;
}
.fs-30 {
  font-size: 30px !important;
}
.fs-200 {
  font-size: 200px !important;
}
.fw-300 {
  font-weight: 300 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.fs-normal {
  font-style: normal;
}
.font-theme {
  font-family: "Montserrat";
}
.font-san {
  font-family: $openSan !important;
  font-style: normal;
}
.bg-none {
  background-color: transparent !important;
}
.bg-lightRed {
  background-color: rgba(255, 0, 0, 0.11) !important;
}
.bg-skyBlue {
  background-color: rgb(34 162 255 / 12%) !important;
}
.bg-white-33 {
  background: rgb(255 255 255 / 34%) !important;
}
.bg-disable {
  background: #f1f1f1 !important;
}
.border-05 {
  border-bottom: 0.5px solid #000034 !important;
}
.border-goals {
  border: 1px solid #b4b4b4 !important;
}
.border-onboarding {
  border: 1px solid #989898 !important;
}
.border-bottom-onboarding {
  border-bottom: 1px solid #989898 !important;
}
.br-0 {
  border-radius: 0px !important;
}
.br-10 {
  border-radius: 10px !important;
}
.br-15 {
  border-radius: 15px !important;
}
.br-20 {
  border-radius: 20px !important;
}
.br-25 {
  border-radius: 25px !important;
}
.br-30 {
  border-radius: 30px !important;
}
.margin-left-18 {
  margin-left: 18px;
}
.line-height-20 {
  line-height: 20px !important;
}
.line-height-30 {
  line-height: 30px !important;
}
.pointer {
  cursor: pointer !important;
}
.modal-backdrop {
  background: #64646445;
}
.z-999 {
  z-index: 999 !important;
}
.height-95 {
  height: 92px !important;
}
.loader-inline {
  background: rgb(0 0 0 / 30%);
  border-radius: 15px 15px 15px 15px;
  z-index: 5;
}
.outline-0 {
  outline: 0px;
}
// Custom border for goals buttons
.border-custom-1 {
  border: 0.5px solid rgba(0, 102, 255, 0.16) !important;
}
.border-custom-2 {
  border: 0.5px solid rgba(255, 0, 0, 0.16) !important;
}
.not-allowed {
  cursor: not-allowed !important;
}
.timeline-card {
  height: 68px !important;
}
.text-indent-0 {
  text-indent: 0 !important;
}
.border-location-create {
  border: 1px solid #c4c4c4 !important;
}
.setting-color {
  color: #121212 !important;
}
.setting-label {
  color: #2e2e2e !important;
}
.border-location-left {
  border-left: 1px solid #c4c4c4 !important;
}
.timeline-card-active {
  border: 1px solid #f178b6 !important;
}
.location-detail-label {
  color: #5a5a5a !important;
}
.text-indent {
  text-indent: 20px !important;
}
.height-50 {
  height: 50px !important;
}
.opacity-1 {
  opacity: 1 !important;
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #f04858;
  margin: -10px 5px;
}
.filter-label {
  color: #0e0e0e !important;
}
.edit-label {
  color: #212121 !important;
}
.edit-border {
  border-bottom: 0.5px solid #303030 !important;
}
